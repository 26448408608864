import { useState } from "react"

import * as validator from "validator"
import { useUserData } from "../services/userData"

interface LegalNameAndEmailFormProps {
    requireEmail?: boolean
}

const LegalNameAndEmailForm: React.FC<LegalNameAndEmailFormProps> = ({ requireEmail }: LegalNameAndEmailFormProps) => {
    const [legalName, setLegalName] = useState('')
    const [email, setEmail] = useState<string | undefined>()
    const [error, setError] = useState('')
    const [serverActivity, setServerActivity] = useState(false)
    const { setLegalNameAndEmail } = useUserData()

    const saveLegalNameAndEmail = async (e: React.FormEvent) => {
        setError('')
        e.preventDefault()
        if (!!legalName) {
            if (requireEmail && (!email || !validator.isEmail(email ?? ""))) {
                setError('Please enter a valid email address')
                return
            }
            setServerActivity(true)
            await setLegalNameAndEmail({ legalName, email })
            setServerActivity(false)
        }
    }

    const isSubmittable = !!legalName && (!requireEmail || (!!email && validator.isEmail(email ?? "")))

    return (<div className="col-md-4 d-flex flex-column justify-content-between mb-4">
        <h3 className="mb-0">Required info</h3>
        <p><strong>Step 1:</strong> Set up your account</p>
        <div className="card">
            <div className="card-body">
                <p className="text-muted">ℹ️ When making a bank transfer we need to pass on your legal name. For that reason please make sure to enter it correctly below.<br /><br />If, for whatever reason, you need to change it in the future please contact support.</p>
                {error &&
                    <div className="alert alert-danger text-center mx-auto mb-3">{error}</div>
                }
                <form onSubmit={saveLegalNameAndEmail}>
                    <div className="form-group">
                        <input type="text" className="form-control my-2" placeholder="Your legal name..." id="legalName" value={legalName} onChange={(e) => setLegalName(e.target.value)} />
                    </div>
                    {requireEmail &&
                        <div className="form-group">
                            <input type="email" className="form-control my-2" placeholder="Email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    }
                    <button type="submit" className="btn btn-primary w-100" disabled={!isSubmittable || serverActivity}>
                        {serverActivity
                            ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            : "Continue"
                        }
                    </button>
                </form>
            </div>
        </div>
    </div>)
}

export default LegalNameAndEmailForm